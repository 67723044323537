<template>
  <div class="tools">
    <el-form style="padding:50rem;">
      <div class="data">
        <!--        原始数据-->
        <div class="item" v-for="(item,index) in coach" :key="'coach_'+index">
          <el-form-item class="details" label="校区">
            {{item.school_name}}
          </el-form-item>
          <el-form-item class="details" label="手动单">
            {{item.manual}}
          </el-form-item>
          <el-form-item class="details" label="自动单">
            {{item.auto}}
          </el-form-item>
          <div class="handle">
            <div class="add" v-if="showAdd(index,0)" @click="onAdd(index,0)"><i class="el-icon-plus"></i></div>
            <div class="remove"v-if="showRemove(index,0)" @click="onRemove(index,0)"><i class="el-icon-delete"></i></div>
          </div>
        </div>
        <!--        新增数据-->
        <div class="item" v-for="(item,index) in coachAdd" :key="'coach_add_'+index">
          <el-form-item class="details" label="校区" size="mini">
            <el-select placeholder="请选择校区" v-model="coachAdd[index].school_id" @change="onChangeSchool($event,index)" ref="mySelect">
              <el-option
                  v-for="item in schoolList"
                  :key="item.id"
                  :label="item.school_name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="details" label="手动单">
            <el-input-number step-strictly v-model.number="coachAdd[index].manual" type="number" :min="1" placeholder="请输入分钟数" size="mini"></el-input-number>
          </el-form-item>
          <el-form-item class="details" label="自动单">
            <el-input-number step-strictly v-model.number="coachAdd[index].auto" type="number" :min="1" placeholder="请输入分钟数" size="mini"></el-input-number>
          </el-form-item>
          <div class="handle">
            <div class="add" @click="onAdd(index,1)" v-if="showAdd(index,1)"><i class="el-icon-plus"></i></div>
            <div class="remove" @click="onRemove(index,1)" v-if="showRemove(index,1)"><i class="el-icon-delete"></i></div>
          </div>
        </div>
      </div>

    </el-form>
    <div class="buttons">
      <el-button size="mini" type="primary" @click="submit">提交</el-button>
      <!--      <el-button size="mini" @click="reset">重置</el-button>-->
    </div>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"index","meta":{"title":"列表"}}},
  data(){
    return {
      // 源数据(重置所需)
      sources:[],
      // 原始数据
      coach:[],
      // 新增数据
      coachAdd:[],
      // 校区列表
      schoolList: [],
    }
  },
  computed:{
    dataTotal(){
      return this.coach.length + this.coachAdd.length;
    },
    showAdd(){
      return (index,type)=>{
        let add = false;
        let dLen = this.coach.length;
        let aLen = this.coachAdd.length;
        if (dLen > 0 && aLen === 0){
          add = index === dLen-1;
        }else if(dLen > 0 && aLen > 0){
          if (type === 1)
            add = index === aLen - 1;
        }else if (dLen === 0 && aLen > 0){
          add = index === aLen - 1
        }
        return add;
      }

    },
    showRemove(){
      return (index,type)=>{
        let remove = true;
        let dLen = this.coach.length;
        let aLen = this.coachAdd.length;
        if (dLen + aLen <=1)remove = false;
        return remove;
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods:{
    /**
     * @desc 获取初始数据
     */
    getData(){
      this.$_axios2.get("/api/tool/coach-time/details").then(res=>{
        let data = res.data;
        if (data.status === 0) {
          this.coach = data.data;
          this.sources = JSON.parse(JSON.stringify(data.data))
        }
        if (data.data.length === 0)this.onAdd();
      }).catch(err=>{
      })
      this.$_axios2.get("/api/tool/dorm/school").then(res=>{
        let data = res.data;
        if (data.status === 0)
          this.schoolList = data.data;
      }).catch(err=>{
      })
    },
    /**
     * @desc 添加校区以及人数事件
     * @param index 操作的数据下标
     * @param type 类型 , 0 已保存, 1 新增的
     */
    onAdd(index,type){
      this.coachAdd.push({});
    },
    /**
     * @desc 删除校区及人数事件
     * @param index 操作的数据下标
     * @param type 类 型 , 0 已保存, 1 新增的
     */
    onRemove(index,type){
      if (type === 0){
        this.coach.splice(index,1);
      }else {
        this.coachAdd.splice(index,1);
      }
    },
    /**
     * @desc 提交数据
     */
    submit(){
      let d1 = this.coach,
          d2 = this.coachAdd,
          sr = this.sources,
          message = null,
          isEmpty = (val)=>{
            return val == null || val === "";
          };
      let result = [];
      d1.forEach(item=>{
        const manual = parseInt(item.manual),
            auto = parseInt(item.auto);
        result.push([item.school_id,manual,auto]);
      })
      if (!d2.length && d1.length === sr.length)
        message = "数据无更改,无需提交!"
      for (let item of d2){
        if (item.school_id!=null && !isEmpty(item.manual) && !isEmpty(item.auto)){
          try {
            const manual = parseInt(item.manual),
                auto = parseInt(item.auto);
            if (manual < 1 || auto < 1){
              message = "分钟数不得小于1"
            }else{
              result.push([item.school_id,manual,auto])
            }
          }catch (e){
            message = "分钟数必须为整数!"
          }


        }else{
          message = "请将数据填写完整";
        }
      }
      if (message == null){
        this.$_axios2.post("/api/tool/coach-time/add",{
          muster:result
        }).then(res=>{
          let data = res.data;
          if (data.status === 0){
            this.coach = this.coach.concat(this.coachAdd);
            this.sources = JSON.parse(JSON.stringify(this.coach));
            this.coachAdd = [];
            this.$message.success({
              message:"保存成功",
              showClose:true
            })
          }else{
            this.$message.error({
              message:data.msg,
              showClose:true
            })
          }
        })
      }else{
        this.$message.warning({
          message:message,
          showClose:true
        })
      }
    },
    /**
     * @desc 重置按钮事件
     */
    reset(){
      if (!this.coach.length)
        this.coachAdd = [{}];
      else
        this.coachAdd = [];
      this.coach = JSON.parse(JSON.stringify(this.sources))
    },
    /**
     * @desc 当校区被选择
     * @param e 选中的值
     * @param index 操作的数据的下标
     */
    onChangeSchool(e,index){
      setTimeout(()=>{
        this.coachAdd[index].school_name = this.$refs.mySelect[0].selectedLabel;
      },20)

      // this.coachAdd[index].school_name = this.$refs.mySelect[0].selectedLabel;
    },
  }

}
</script>

<style lang="scss" scoped>
.tools{
  position: relative;
  .el-form{

    overflow-y: scroll;
    height: calc(100vh - 64rem - 56rem);
  }
  .data{
    width:100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20rem;
    .el-input-number{
      width: 180rem;
    }
    .item{
      display: flex;
      border-radius: 10rem;
      border:1rem solid #ddd;
      margin-top: 20rem;
      flex:1;
      padding: 10rem;
      align-items: center;
      justify-content: space-between;
      .details{
        min-width: 330rem;
        width: 17.1875vw;
        margin-bottom: 0;
      }
      .handle{
        width:100rem;
        div{
          display: inline-block;
          cursor: pointer;
          font-size: 16rem;
          color: #666;
          border-bottom:1rem solid #eee;
          border-right:1rem solid #eee;
          margin-left: 10rem;
          text-align: center;
          line-height: 40rem;
          width: 40rem;
          height: 40rem;
          border-radius: 20rem;
        }
      }
    }
  }
  .buttons{
    position: absolute;
    padding: 20rem;
    bottom: 0;
    background: white;
    border-top: 1rem solid #eee;
    width:100%;

  }
}
</style>
